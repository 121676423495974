import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Banner from '../../assets/images/cloud-private-banner.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import 'swiper/css';
import 'swiper/css/navigation';
import * as styles from '../storage/index.module.less';
import Button from '../../components/Button';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { contactUsModalShow } from '../../components/ContactUsModal';
import { cls } from '../../utils';

export const NICE_LIST = [
  {
    title: '抗振设计',
    desc: '采用军工常用的内外加固设计，抗振动，通过GB-T 4798标准测试（电工电子产品应用环境条件 第5部分：地面车辆使用），振动环境使用无忧。',
  },
  {
    title: '自然散热',
    desc: '无风扇全金属导冷设计，无音无尘，适应恶劣环境，防盐雾，防滴露，防霉菌。',
  },

  {
    title: '断电保护',
    desc: '硬盘断电保护设计，避免异常断电对硬盘造成的损伤。',
  },

  {
    title: '低功耗',
    desc: '典型功耗35W，无需大功率电源供应。',
  },
  {
    title: '形态小巧',
    desc: '四盘位存储设备，可灵活部署在汽车上使用。',
  },
  {
    title: '自主可控',
    desc: '基础硬件+底层软件均为国产自主研发，供应链不受限，满足政企用户要求。',
  },
];

export default function CloudPrivatePage() {
  const [machActive, setMachActive] = useState(1);
  return (
    <Layout>
      <BannerWrap
        img={Banner}
        title="土星云分布式存储服务器"
        desc="土星云分布式车载存储服务器可用于大容量非结构数据存储，在满足数据存储性能的同时，解决了现有存储服务器功耗高、稳定性差、环境要求高、价格高等一系列问题。支持对象存储、文件存储、块存储，能满足和车机娱乐系统兼容的多样性，可作为娱乐外设设备加装到车机系统中。"
      />
      <SectionWrap
        title="车载数据存储服务器"
        background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.machContainer}>
          <div className={styles.machSwiper}>
            <div className={styles.imgViewCard}>
              <img src={`/saturn-product/dn110m-cs-car/${machActive}.jpg`} alt="" />
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              height={80}
              loop={false}
              navigation
              modules={[Navigation]}
              style={{ padding: '0 40px' }}>
              {Array.from({ length: 3 }).map((item, i) => {
                const index = i + 1;
                let itemClassName = styles.machItem;
                if (index === machActive) {
                  itemClassName += ' ' + styles.machItemActive;
                }
                return (
                  <SwiperSlide key={index} style={{ width: 180 }}>
                    <div
                      className={itemClassName}
                      onClick={() => {
                        setMachActive(index);
                      }}>
                      <img src={`/saturn-product/dn110m-cs-car/${index}.jpg`} alt="" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className={styles.machInfo}>
            <div className={styles.machTitle}>车载数据存储服务器</div>
            <div className={styles.machDesc}>
              土星云分布式存储服务器DC110M-CS车载数据存储是国科环宇自主研发的面向海量非结构化数据的分布式存储产品。采用了基于军工、航天电子技术的金属导冷散热和低功耗设计，搭载了基于望获实时Linux系统内核的分布式存储软件。该产品具有自然散热、超低功耗、高可靠无限扩容和易安装等特点。可满足海量的非结构化数据存储需求。
            </div>
            <div className={styles.machOperate}>
              <a
                href="/saturn-product/dn110m-cs-car/doc.pdf"
                download="土星云分布式存储服务器DC110M-CS车载数据存储产品规格书">
                <Button type="primary" style={{ marginRight: 20 }}>
                  资料下载
                </Button>
              </a>
              <Button
                type="outline"
                onClick={() => {
                  contactUsModalShow();
                }}>
                购买咨询
              </Button>
            </div>
          </div>
        </div>
      </SectionWrap>
      <SectionWrap title="产品特性" background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.niceList}>
          {NICE_LIST.map((item, index) => {
            return (
              <div className={styles.niceItem} key={index}>
                <img className={styles.niceIcon} src={`/images/cloud-private-nice-5.png`} alt="" />
                <div className={styles.niceTitle}>{item.title}</div>
                <div className={styles.niceDesc}>{item.desc}</div>
              </div>
            );
          })}
          {/* <div className={styles.niceItem} style={{ visibility: 'hidden' }}></div> */}
        </div>
      </SectionWrap>
      <SectionWrap title="技术规格">
        <table className={cls(styles.normsTable, styles.type2)}>
          <colgroup>
            <col width={100} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan={3} style={{ textAlign: 'center' }}>
                土星云分布式存储服务器DC110M-CS车载数据存储
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={2}>存储</td>
              <td>盘位数</td>
              <td>4盘位</td>
            </tr>
            <tr>
              <td>存储容量</td>
              <td>标配64TB</td>
            </tr>
            <tr>
              <td>处理器</td>
              <td>CPU</td>
              <td>RK3399，6核(2*Cortex-A72+4*Cortex-A53 )，主频最高1.8GHz</td>
            </tr>
            <tr>
              <td>内存</td>
              <td>内存容量</td>
              <td>3GB</td>
            </tr>
            <tr>
              <td rowSpan={2}>I/O</td>
              <td>千兆以太网</td>
              <td>2 Ports 1000Base-T(RJ45)</td>
            </tr>
            <tr>
              <td>USB 3.0</td>
              <td>1 Ports (Type-A)</td>
            </tr>
            <tr>
              <td rowSpan={8}>支持软件</td>
              <td>操作系统</td>
              <td>望获操作系统（冗余备份）</td>
            </tr>
            <tr>
              <td>系统架构</td>
              <td>分布式存储</td>
            </tr>
            <tr>
              <td>存储类型</td>
              <td>对象存储/块存储/文件存储</td>
            </tr>
            <tr>
              <td>存储协议</td>
              <td>S3/CIFS/iSCSI/NFS/glusterfs/FTP/SMB/btrfs等</td>
            </tr>
            <tr>
              <td>数据加密</td>
              <td>国密算法SM2/SM3/SM4</td>
            </tr>
            <tr>
              <td>数据冗余</td>
              <td>纠删码（M+N）</td>
            </tr>
            <tr>
              <td>管理工具</td>
              <td>WEB界面/命令行、S3工具</td>
            </tr>
            <tr>
              <td>系统功能</td>
              <td>
                故障巡检、自动修复，硬盘保护、硬盘失效分析，系统状态监测、自动报警，远程重启、断电保护，硬盘智能调度、寿命保护
              </td>
            </tr>
            <tr>
              <td>功耗</td>
              <td>功耗 (最大)</td>
              <td>＜55W</td>
            </tr>
            <tr>
              <td>电源</td>
              <td>供电模式</td>
              <td>12V DC</td>
            </tr>
            <tr>
              <td rowSpan={4}>环境</td>
              <td>工作温度</td>
              <td>0℃~+50℃</td>
            </tr>
            <tr>
              <td>工作湿度</td>
              <td>40℃@95% 相对湿度，无冷凝</td>
            </tr>
            <tr>
              <td>储存温度</td>
              <td>-40℃~+85℃</td>
            </tr>
            <tr>
              <td>储存湿度</td>
              <td>储存湿度：60℃@95% 相对湿度，无冷凝</td>
            </tr>
            <tr>
              <td>机械</td>
              <td>尺寸</td>
              <td>193 宽 × 170 高 × 182 深（单位：mm）</td>
            </tr>
          </tbody>
        </table>
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="私有云" />;
}
